<template>
  <dv-row
    :theme="field.theme"
    :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`"
    :hint="field.hint"
    class="flex-col"
    :class="{
      openai: openaiEnabled || writerExtensionEnabled,
    }"
    :field="field"
  >
    <template v-slot:label>
      <div class="flex justify-between items-center w-100">
        <p>{{ field.formField.label }} {{ field.mandatory ? "*" : "" }}</p>
        <property-writer-iframe
          v-if="writerExtensionEnabled"
          :extension-name="writerExtensionName"
          :field="field.fieldName.replace('_note', '')"
          :field-name="field.formField.label"
          :resource-id="record.id"
          :resource-type="resourceType"
          @submit="record[field.fieldName] = $event"
        />
        <property-writer-link
          v-else-if="openaiEnabled"
          :field="field.fieldName.replace('_note', '')"
          :field-name="field.formField.label"
          :resource-id="record.id"
          :resource-type="resourceType"
          @submit="record[field.fieldName] = $event"
        />
      </div>
    </template>
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'w-full',
      ]"
    >
      <nice-input
        v-bind="field.formField.additionalParams?.(field, record[field.fieldName])"
        type="textarea"
        ref="textarea"
        v-model="record[field.fieldName]"
        :readonly="field.readonly"
        :autosize="{ minRows: 3, maxRows: 60 }"
        :class="{
          'disabled-visibility-text': field.readonly,
          'inherit-bg-color': !record[field.fieldName] && field.color,
        }"
        :style="!record[field.fieldName] && `background-color: ${field.color}`"
        class="rounded"
        :data-value-missing="!record[field.fieldName]"
      />
      <footer v-if="snippets && snippets.length > 0 && !field.readonly" class="flex justify-end mt-1">
        <nice-select
          @change="snippetSelected"
          :options="snippets"
          size="small"
          :placeholder="$t('detailView.snippetPh')"
          :class="{ 'disabled-visibility-text': field.readonly }"
        />
      </footer>
    </div>
  </dv-row>
</template>

<script>
import PropertyWriterLink from "@/components/property/PropertyWriterLink.vue"
import PropertyWriterIframe from "@/components/property/PropertyWriterIframe.vue"
import { COMPONENT_EXTENSIONS } from "@/config/extensions"

export default {
  props: ["record", "field"],
  components: { PropertyWriterLink, PropertyWriterIframe },
  methods: {
    snippetSelected(val) {
      const found = this.snippets.find(o => o.id == val)

      if (found) {
        const textareaElement = this.$refs.textarea.$refs.input.$el.getElementsByTagName("textarea")[0]
        if (!textareaElement) return

        const start = textareaElement.selectionStart
        const end = textareaElement.selectionEnd
        const text = this.record[this.field.fieldName] || ""

        const newText = text.substring(0, start) + found.body + text.substring(end)
        this.record[this.field.fieldName] = newText

        this.$nextTick(() => {
          textareaElement.selectionStart = textareaElement.selectionEnd = start + found.body.length
          textareaElement.focus()
        })
      }
    },
  },
  computed: {
    snippets() {
      if (this.field.formField.snippets) {
        return this.$db.shopData.textSnippets.filter(
          o => o.category == this.field.formField.snippets && o.role != "long"
        )
      }
    },
    resourceType() {
      return this.$route.path.split("/")[2] === "properties" ? "property" : "project"
    },
    openaiEnabled() {
      return (
        this.$db.shopData.openaiConnected &&
        !this.record.received_via_mls &&
        this.record.id &&
        [
          "description_note",
          "location_note",
          "other_note",
          "facebook_post_description",
          "instagram_post_description",
        ].includes(this.field.fieldName)
      )
    },
    writerExtensionName() {
      return this.$db.shopData.extensions.find(e => e.enabled && !!COMPONENT_EXTENSIONS["propertyWriter"][e.id])?.id
    },
    writerExtensionEnabled() {
      return (
        this.writerExtensionName &&
        !this.record.received_via_mls &&
        this.record.id &&
        ["description_note", "location_note", "other_note"].includes(this.field.fieldName) &&
        !this.field.readonly
      )
    },
  },
}
</script>

<style>
.disabled-visibility-text > textarea {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
.disabled-visibility-text > .el-input > span {
  display: none !important;
}
.openai > .box-cell > span {
  width: 100%;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
.inherit-bg-color .el-input__wrapper {
  background-color: inherit !important;
}
</style>
