import { getClientLocales } from "@/utils/get-client-locales"
import * as _ from "lodash"
import { FormField, FormFieldScope, FormFieldType } from "../../interfaces"
import countries from "./countries"
import nationalities from "./nationalities"

const FORM_FIELDS: Record<string, FormField> = {
  brokerId: {
    dbOptions: "activeBrokers",
    type: FormFieldType.dropdown,
    group: "supervisor",
    filterable: true,
    filterName: "responsible_broker",
    readonly: ({ db, record }) =>
      record && ![record.brokerId, record.secondBrokerId].includes(db.broker.id) && !db.broker.canChangeBroker,
  },
  teamId: {
    dbOptions: "teams",
    type: FormFieldType.dropdown,
    notForLp: true,
    group: "general",
    filterable: true,
  },

  // Stammdaten
  name: {
    label: "name",
    type: FormFieldType.name,
    cantHaveRights: true,
  },
  commercial: {
    type: FormFieldType.boolean,
  },

  // Kontakdaten
  emails: {
    type: FormFieldType.emails,
    group: "client",
    filterable: true,
  },
  homeCell: {
    type: FormFieldType.phone,
    group: "client",
    filterable: true,
  },
  homePhone: {
    type: FormFieldType.phone,
    group: "client",
    filterable: true,
  },
  photoUrl: {
    type: FormFieldType.photo,
  },
  locked: {
    type: FormFieldType.boolean,
    group: "general",
    filterable: true,
  },
  archived: {
    type: FormFieldType.boolean,
    group: "general",
    filterable: true,
  },
  newsletter: {
    type: FormFieldType.dropdown,
    options: [
      { id: null, name: "null", translate: true },
      { id: true, name: "newsletterTrue", translate: true },
      { id: false, name: "newsletterFalse", translate: true },
    ],
    group: "general",
    filterable: true,
  },
  propertyMailingWanted: {
    type: FormFieldType.boolean,
    group: "general",
    filterable: true,
  },
  clientSyncBrokerIds: {
    type: FormFieldType.multiselect,
    dbOptions: "activeBrokers",
    group: "general",
    filterable: true,
    filterName: "contact_sync_broker_ids",
  },
  brokerIds: {
    type: FormFieldType.multiselect,
    dbOptions: "brokers",
    group: "supervisor",
    filterable: true,
  },
  departmentIds: {
    type: FormFieldType.multiselect,
    dbOptions: "departments",
    group: "supervisor",
    filterable: ({ db }) => db.broker.canSeeAllClients && db.broker.departmentIds && db.broker.departmentIds.length,
  },
  homeAddress: {
    type: FormFieldType.address,
    prefix: "home_",
  },
  homeUrl: {
    type: FormFieldType.link,
  },
  messageSalutation: {},
  locale: {
    type: FormFieldType.dropdown,
    options: ({ db, t }) => {
      return getClientLocales(db).map(value => ({
        id: value,
        name: t(`locales.${value}`) as string,
      }))
    },
    group: "general",
    filterable: true,
  },
  warningNotice: { type: FormFieldType.string },

  company: {
    type: FormFieldType.string,
    group: "company",
    filterable: true,
  },
  position: {
    type: FormFieldType.string,
    group: "company",
    filterable: true,
  },
  officeAddress: {
    type: FormFieldType.address,
    prefix: "office_",
  },
  officeUrl: {
    type: FormFieldType.link,
  },
  officePhone: {
    type: FormFieldType.phone,
  },
  officeCell: {
    type: FormFieldType.phone,
  },
  homeFax: { type: FormFieldType.string },
  officeFax: { type: FormFieldType.string },
  secondBrokerId: {
    dbOptions: "activeBrokers",
    type: FormFieldType.dropdown,
    group: "supervisor",
    filterable: true,
    filterName: "second_broker",
    readonly: ({ db, record }) =>
      record && ![record.brokerId, record.secondBrokerId].includes(db.broker.id) && !db.broker.canChangeBroker,
  },
  clientSourceId: {
    dbOptions: "clientSources",
    type: FormFieldType.dropdown,
    group: "general",
    filterable: true,
    filterName: "source_id",
  },
  clientStatusId: {
    dbOptions: "clientStatuses",
    type: FormFieldType.dropdown,
    group: "general",
    filterable: true,
    filterName: "status_id",
    readonly: ({ db, record }) =>
      record && ![record.brokerId, record.secondBrokerId].includes(db.broker.id) && !db.broker.canChangeClientStatus,
  },
  groupIds: {
    options: ({ db }) => {
      const superGroups = db.get("superGroups")
      return db
        .get("clientGroups")
        .map(g => {
          const superGroup = superGroups.find(sg => sg.id == g.superGroupId)
          return {
            id: g.id,
            name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
          }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    type: FormFieldType.multiselect,
    group: "general",
    filterable: true,
  },
  mailchimpInterestIds: {
    options: ({ db }) => {
      return db
        .get("mailchimpInterests")
        .map(interest => {
          return {
            id: interest.identifier,
            name: interest.name,
          }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    type: FormFieldType.multiselect,
    group: "general",
    filterable: true,
  },
  description: {
    type: FormFieldType.text,
    group: "general",
    filterable: true,
  },
  purchaseBudget: {
    type: FormFieldType.number,
    unit: "currency",
    visible: db => db.shopData.allEnabledExtensions?.includes("justhome") ?? false,
  },
  followupDate: {
    type: FormFieldType.date,
    group: "general",
    filterable: true,
  },
  inquiryDepartmentId: {
    type: FormFieldType.dropdown,
    group: "general",
    filterable: true,
    dbOptions: "departmentsForUseInInquiryDistribution",
  },
  keepDataTill: {
    type: FormFieldType.date,
    group: "gdpr",
    filterable: true,
  },
  clientReasonId: {
    type: FormFieldType.dropdown,
    dbOptions: "clientReasons",
    group: "gdpr",
    filterable: true,
  },
  gdprStatus: {
    type: FormFieldType.dropdown,
    options: [
      { id: 0, name: "null", translate: true },
      { id: 1, name: "gdprStatus1", translate: true },
      { id: 2, name: "gdprStatus2", translate: true },
      { id: 3, name: "gdprStatus3", translate: true },
    ],
    group: "gdpr",
    filterable: true,
  },
  acceptContact: {
    type: FormFieldType.boolean,
    group: "general",
    filterable: true,
  },

  rating: {
    type: FormFieldType.dropdown,
    options: [
      { id: 0, name: "☆☆☆" },
      { id: 1, name: "★☆☆" },
      { id: 2, name: "★★☆" },
      { id: 3, name: "★★★" },
    ],
    group: "general",
    filterable: true,
  },
  equity: { type: FormFieldType.string },
  handoverDate: { type: FormFieldType.string },
  income: { type: FormFieldType.string },
  rentDate: { type: FormFieldType.string },
  mvsigned: {
    type: FormFieldType.boolean,
  },
  hvsigned: {
    type: FormFieldType.boolean,
  },

  // GwG
  dob: {
    type: FormFieldType.date,
    group: "gwg",
    filterable: true,
  },
  age: { component: "client-age" },
  birthName: { type: FormFieldType.string },
  birthPlace: { type: FormFieldType.string },
  birthCountry: {
    type: FormFieldType.dropdown,
    options: countries as any,
    group: "gwg",
    filterable: true,
  },
  passType: {
    type: FormFieldType.dropdown,
    options: [
      { id: "Personalausweis", name: "passType1", translate: true },
      { id: "Reisepass", name: "passType2", translate: true },
      { id: "Sonstige", name: "passType3", translate: true },
    ],
  },
  identityNumber: { type: FormFieldType.string },
  issuingAuthority: { type: FormFieldType.string },
  taxIdentificationNumber: { type: FormFieldType.string },
  nationality: {
    type: FormFieldType.dropdown,
    options: _.sortBy(
      Object.entries(nationalities).map(([k, v]) => ({
        id: k,
        name: v,
      })),
      "name"
    ),
    group: "gwg",
    filterable: true,
  },
  legalForm: { type: FormFieldType.string },
  registerNumber: { type: FormFieldType.string },
  conspicuity: {
    type: FormFieldType.text,
  },

  projectIds: {
    type: FormFieldType.multiselect,
    dbOptions: "projects",
  },
  ownerships: {
    type: FormFieldType.relationship,
    scope: FormFieldScope.owner,
  },
  partnerships: {
    type: FormFieldType.relationship,
    scope: FormFieldScope.partner,
  },
  associates: {
    type: FormFieldType.relationship,
    scope: FormFieldScope.associate,
  },
  children: {
    type: FormFieldType.children,
    group: "general",
    filterable: true,
    filterName: "children_name",
  },
  phoneNumbers: {
    type: FormFieldType.phones,
  },
  accessBrokerIds: {
    type: FormFieldType.multiselect,
    dbOptions: "brokers",
  },
  accessDepartmentIds: {
    type: FormFieldType.multiselect,
    dbOptions: "departments",
  },
  married: {
    type: FormFieldType.boolean,
  },
  matrimonialPropertyScheme: { type: FormFieldType.string },
  spousesConsent: {
    type: FormFieldType.boolean,
  },
  bankName: { type: FormFieldType.string },
  bankAccountOwner: { type: FormFieldType.string },
  bankIban: { type: FormFieldType.string },
  bankBic: { type: FormFieldType.string },
}

export default FORM_FIELDS
